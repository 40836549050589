import axios from "axios";
import * as React from "react";
import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter } from "react-router";
import LoginForm from "../login-form/LoginForm";
import NavigationBar from "../navigation-bar/NavigationBar";
import PasswordChangeForm from "../password-change-form/PasswordChangeForm";
import PreviewJobTable from "../preview-job-table/PreviewJobTable";
import VehicleMap from "../vehicle-map/VehicleMap";
import VehicleTable from "../vehicle-table/VehicleTable";
import AnonRoute from "./anon-route/AnonRoute";

import "./MainContentContainer.css";
import PrivateRoute from "./private-route/PrivateRoute";

export interface IMainContentContainerProps {}

interface IMainContentContainerState {
  isAuthenticated: boolean;
}

export default class MainContentContainer extends React.Component<
  IMainContentContainerProps,
  IMainContentContainerState
> {
  constructor(props: IMainContentContainerProps) {
    super(props);

    this.state = {
      isAuthenticated: document.cookie.indexOf("xispo-online") !== -1,
    };
  }

  private handleSignIn(): void {
    document.cookie = "xispo-online=1; Secure;SameSite=Strict";

    this.setState({ isAuthenticated: true });
  }

  private handleSignOut(): void {
    document.cookie = "xispo-online= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";

    axios
      .post(
        "/api/authentication/logout",
        {},
        { withCredentials: true, timeout: 5000 }
      )
      .finally(() => {
        this.setState({ isAuthenticated: false });
      });
  }

  public render() {
    return (
      <div className="d-flex flex-column vh-100">
        <BrowserRouter>
          <NavigationBar
            handleSignOut={() => this.handleSignOut()}
            isAuthenticated={this.state.isAuthenticated}
          />
          <Routes>
            <Route
              path="/login"
              element={
                <AnonRoute
                  isAuthenticated={this.state.isAuthenticated}
                  handleUserAuthenticated={() => this.handleSignIn()}
                  component={LoginForm}
                />
              }
            />
            <Route path="/" element={<Navigate to="jobs" />} />
            <Route
              path="/jobs"
              element={
                <PrivateRoute
                  isAuthenticated={this.state.isAuthenticated}
                  handleSignOut={() => this.handleSignOut()}
                  component={PreviewJobTable}
                />
              }
            />
            <Route
              path="/vehicles"
              element={
                <PrivateRoute
                  isAuthenticated={this.state.isAuthenticated}
                  handleSignOut={() => this.handleSignOut()}
                  component={VehicleTable}
                />
              }
            />
            <Route
              path="/map"
              element={
                <PrivateRoute
                  isAuthenticated={this.state.isAuthenticated}
                  handleSignOut={() => this.handleSignOut()}
                  component={VehicleMap}
                />
              }
            />
            <Route
              path="/changePassword"
              element={
                <PrivateRoute
                  isAuthenticated={this.state.isAuthenticated}
                  handleSignOut={() => this.handleSignOut()}
                  component={PasswordChangeForm}
                />
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
