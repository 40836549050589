import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import * as React from "react";
import { ButtonGroup, Container, ToggleButton } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import { IPreviewJob } from "../../../shared-interfaces/dto/IPreviewJob";
import { BaseTable } from "./BaseTable";

import Form from "react-bootstrap/Form";

export interface IPreviewJobDesktopTableProps extends WithTranslation {
  jobs: Array<IPreviewJob>;
  showTomorrow: boolean;
  setShowTomorrow(value: boolean): void;
}

interface IPreviewJobDesktopTableState {
  filter: string;
}

class PreviewJobDesktopTable extends React.Component<
  IPreviewJobDesktopTableProps,
  IPreviewJobDesktopTableState
> {
  private columns: ColumnDef<IPreviewJob, any>[];

  constructor(props: IPreviewJobDesktopTableProps) {
    super(props);

    this.state = { filter: "" };

    const { t } = this.props;

    const columnHelper = createColumnHelper<IPreviewJob>();

    this.columns = [
      columnHelper.accessor("startTimestamp", {
        id: "date",
        header: () => t("preview_job_table:date"),
        cell: (data) => this.toDate(data.getValue()),
      }),

      columnHelper.accessor("startTimestamp", {
        header: () => t("preview_job_table:start_timestamp"),
        cell: (data) => this.toTime(data.getValue()),
      }),

      columnHelper.accessor("pickupTimestamp", {
        header: () => t("preview_job_table:pickup_timestamp"),
        cell: (data) => this.toTime(data.getValue()),
      }),

      columnHelper.accessor("appointmentTimestamp", {
        header: () => t("preview_job_table:appointment_timestamp"),
        cell: (data) => this.toTime(data.getValue()),
      }),

      columnHelper.accessor("responseCode", {
        header: () => t("preview_job_table:response_code"),
      }),

      columnHelper.accessor("specialJobType", {
        header: () => t("preview_job_table:special_job_type"),
      }),

      columnHelper.accessor("sceneAddress", {
        header: () => t("preview_job_table:scene_address"),
      }),

      columnHelper.accessor("destinationAddress", {
        header: () => t("preview_job_table:destination_address"),
      }),

      columnHelper.accessor("vehicle", {
        header: () => t("preview_job_table:preplanned"),
        cell: (data) => data.getValue()?.toUpperCase(),
      }),
    ];
  }

  private toTime(timestamp: Date): string {
    if (!timestamp) {
      return "";
    }

    let time = timestamp.toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return time;
  }

  private toDate(timestamp: Date): string {
    return timestamp.toLocaleDateString(navigator.language);
  }

  private getTable(): React.ReactNode {
    const { t } = this.props;

    if (this.props.jobs.length > 0) {
      return (
        <React.Fragment>
          <div className="my-2 float-end">
            <Form.Control
              type="text"
              onChange={(event) =>
                this.setState({
                  filter: event.target.value,
                })
              }
              placeholder={t("preview_job_table:search")}
            />
          </div>
          <div className="float-start overflow-break-words w-100">
            <BaseTable<IPreviewJob>
              data={this.props.jobs}
              columns={this.columns}
              filter={this.state.filter}
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="float-start w-100 text-center m-4">
        {t("preview_job_table:no_jobs_available")}
      </div>
    );
  }

  public render() {
    const { t } = this.props;

    return (
      <Container>
        <div>
          <div className="my-2 float-start">
            <ButtonGroup>
              <ToggleButton
                key="day-select-1"
                id="day-select-1"
                type="radio"
                variant="outline-primary"
                value="false"
                checked={!this.props.showTomorrow}
                onChange={() => this.props.setShowTomorrow(false)}
              >
                {t("preview_job_table:today")}
              </ToggleButton>
              <ToggleButton
                id="day-select-2"
                key="day-select-2"
                type="radio"
                variant="outline-primary"
                value="true"
                checked={this.props.showTomorrow}
                onChange={() => this.props.setShowTomorrow(true)}
              >
                {t("preview_job_table:tomorrow")}
              </ToggleButton>
            </ButtonGroup>
          </div>
          {this.getTable()}
        </div>
      </Container>
    );
  }
}

export default withTranslation()(PreviewJobDesktopTable);
