import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";

import { Table } from "react-bootstrap";
import { IDtoWithId } from "../../../shared-interfaces/dto/IDtoWithId";
import React from "react";

interface IBaseTableProps<T extends IDtoWithId> {
  data: T[];
  columns: ColumnDef<T, any>[];
  filter?: string;
  getExpandedItem: (row: Row<T>) => React.ReactNode;
  getRowCanExpand: (row: Row<T>) => boolean;
}

export function BaseTable<T extends IDtoWithId>(props: IBaseTableProps<T>) {
  const { data, columns, filter } = props;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: props.getRowCanExpand,
    globalFilterFn: "includesString",
    state: {
      globalFilter: filter,
    },
  });

  return (
    <Table bordered striped hover variant="light">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <React.Fragment key={row.id}>
            <tr
              style={row.getCanExpand() ? { cursor: "pointer" } : {}}
              onClick={() => (row.getCanExpand() ? row.toggleExpanded() : null)}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
            {row.getIsExpanded() ? (
              <tr key={row.id + "-expanded"}>
                <td colSpan={2}>{props.getExpandedItem(row)}</td>
              </tr>
            ) : null}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
}
