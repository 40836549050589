import { createColumnHelper, ColumnDef, Row } from "@tanstack/react-table";
import * as React from "react";
import { Container, Table } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import { BaseTable } from "./BaseTable";

import "./VehicleDesktopTable.css";

import Form from "react-bootstrap/Form";
import { IVehicleInformation } from "../../../shared-interfaces/dto/IVehicleInformation";

export interface IVehicleDesktopTableProps extends WithTranslation {
  vehicles: Array<IVehicleInformation>;
  getStatusBadge(statusText: string, hexColor: string): React.ReactNode;
}

interface IVehicleDesktopTableState {
  filter: string;
}

class VehicleDesktopTable extends React.Component<
  IVehicleDesktopTableProps,
  IVehicleDesktopTableState
> {
  private columns: ColumnDef<IVehicleInformation, any>[];

  constructor(props: IVehicleDesktopTableProps) {
    super(props);

    this.state = { filter: "" };

    const { t } = this.props;

    const columnHelper = createColumnHelper<IVehicleInformation>();

    this.columns = [
      columnHelper.accessor("callSign", {
        header: () => t("vehicle_table:call_sign"),
      }),

      columnHelper.accessor("dispositionStatusText", {
        header: () => t("vehicle_table:disposition_status"),
        cell: ({ row }) => {
          return (
            <div>
              {this.props.getStatusBadge(
                row.original.dispositionStatusText,
                row.original.dispositionStatusColor
              )}
            </div>
          );
        },
      }),
    ];
  }

  private getExpandedTable(row: Row<IVehicleInformation>) {
    if (!row.original.jobs || row.original.jobs.length === 0) {
      return null;
    }

    return (
      <Table
        className="expanded-table"
        size="sm"
        borderless={true}
        hover={false}
        bgcolor="transparent"
        style={{ marginBottom: 0 }}
      >
        <thead>
          <tr>
            <th>{this.props.t("vehicle_table:response_code")}</th>
            <th>{this.props.t("vehicle_table:special_job_type")}</th>
            <th>
              {this.props.t("vehicle_table:scene_or_destination_address")}
            </th>
            <th>{this.props.t("vehicle_table:disposition_status")}</th>
          </tr>
        </thead>
        <tbody>
          {row.original.jobs.map((x) => {
            let placeToGo = x.sceneAddress;

            if (x.dispositionStatus === 7 || x.dispositionStatus === 8) {
              placeToGo = x.destinationAddress;
            }

            return (
              <tr key={x.id}>
                <td>{x.responseCode}</td>
                <td>{x.specialJobType}</td>
                <td>{placeToGo}</td>
                <td>
                  {this.props.getStatusBadge(
                    x.dispositionStatusText,
                    x.dispositionStatusColor
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  private getTable(): React.ReactNode {
    const { t } = this.props;

    if (this.props.vehicles.length > 0) {
      return (
        <React.Fragment>
          <div className="my-2 float-end">
            <Form.Control
              type="text"
              onChange={(event) =>
                this.setState({
                  filter: event.target.value,
                })
              }
              placeholder={t("vehicle_table:search")}
            />
          </div>
          <div className="float-start overflow-break-words w-100">
            <BaseTable<IVehicleInformation>
              data={this.props.vehicles}
              columns={this.columns}
              filter={this.state.filter}
              getExpandedItem={(x) => this.getExpandedTable(x)}
              getRowCanExpand={(x) =>
                x.original.jobs && x.original.jobs.length > 0
              }
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="float-start w-100 text-center m-4">
        {t("preview_job_table:no_jobs_available")}
      </div>
    );
  }

  public render() {
    return (
      <Container>
        <div>{this.getTable()}</div>
      </Container>
    );
  }
}

export default withTranslation()(VehicleDesktopTable);
